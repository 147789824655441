import toast from "react-hot-toast";
import { googleMapApiKey } from "../config";
import WebRequest from "../WebRequest";


export const NKTEN = "nkten";

// Function to pad a single digit number with leading zero
function padZero(num) {
    return num < 10 ? '0' + num : num;
}

export function addMapScript() {
    // Load the Google Maps API script with a callback
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapApiKey}&libraries=places&callback=initMap`;
    script.defer = true;
    document.head.appendChild(script);
}


// Function to get the current date in "YYYY-MM-DD" format
export function getFormattedDate(date) {
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}T${padZero(date.getHours())}:${padZero(date.getMinutes())}`;
    return formattedDate;
}


export function adjustDate(minutesForward, date){
    let dtDate;
    if(date){
        dtDate = new Date(date);
    }
    else {
        dtDate = new Date();
    }
    if(minutesForward){
        dtDate = new Date(dtDate.getTime() + minutesForward * 60 * 1000);
    }

    return dtDate;
}


export function compareOnlyDate(date1, date2) {
    const normalizeDate = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());

    const normalizedDate1 = normalizeDate(date1);
    const normalizedDate2 = normalizeDate(date2);

    return Math.sign(normalizedDate1.getTime() - normalizedDate2.getTime());
}



export function getNkten() {
    const nkten = localStorage.getItem(NKTEN);
    return `Bearer ${nkten}`;
}

export async function clearCache(name) {
    if ('caches' in window) {
        if (name) {
            try {
                let cache = await caches.open(name);
                let cacheKeys = await cache.keys();
                await Promise.all(cacheKeys.map(cacheKey => cache.delete(cacheKey)));
            } catch (error) {
                toast.error('Failed to clear cache');
            }
        } else {
            caches.keys().then(cacheNames => {
                return Promise.all(
                    cacheNames.map(cacheName => {
                        return caches.delete(cacheName);
                    })
                );
            }).catch(e => {
                toast.error('Failed to clear cache');
            })
        }
    }
}

function clearCookies() {
    document.cookie.split(";").forEach((cookie) => {
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    });
}



export async function checkUpdates() {
    sessionStorage.clear();
    clearCookies();
    await clearCache();
    window.location.reload();
}


export function hourToMilliseconds(hour){
    return hour * 60 * 60 * 1000;
}


export const NAME_UNAVAILABLE = "Name Unavailable";


export async function CheckAuthentication() {
    try{
        const reponseData = await WebRequest.CheckAuthentication();
        return reponseData.success;
    }catch{
        return false;
    }
}


export function debounce(func, wait) {
    let timeout;
    return function (...args) {
        const context = this;
        const later = function () {
            timeout = null;
            func.apply(context, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}



export async function vehicleDeatilsLoader(params) {
    const vehicleDetailData = await WebRequest.GetVehicleDetails(params);
    if(vehicleDetailData.success && vehicleDetailData.data.vehicleData && vehicleDetailData.data.vehicleData.length > 0) {
        return vehicleDetailData.data.vehicleData[0];
    } else {
        return null;
    }
}


export function isPwa() {
    return ["fullscreen", "standalone", "minimal-ui"].some(
        (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
    );
}


export function isIOS() {
    const ua = window.navigator.userAgent;
    const isIPad = !!ua.match(/iPad/i);
    const isIPhone = !!ua.match(/iPhone/i);
    return isIPad || isIPhone;
};

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}