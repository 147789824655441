import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: null,
}

export const vehicleListSlice = createSlice({
  name: 'vehicleList',
  initialState,
  reducers: {
    updateVehiclesList: (state, action) => {
      state.value = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { updateVehiclesList } = vehicleListSlice.actions;

export default vehicleListSlice.reducer;