import { getNkten } from "./common/common";
import { apiPrefix } from "./config"

/*
Every method will return data like.
{data:{}, success: true/false, message: ""}
*/
class WebRequest {

    static async Login(data) {
        try {
            const response = await fetch(`${apiPrefix}/Login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                return GenerateReturntype(null, false, `HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();
            return responseData;
        } catch (error) {
            return GenerateReturntype(null, false, `Error 230124AS1259: Login Failed`);
        }
    }


    static async ResetPassword(data) {
        try {
            const response = await fetch(`${apiPrefix}/ResetPassword`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                return GenerateReturntype(null, false, `HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();
            return responseData;
        } catch (error) {
            return GenerateReturntype(null, false, `Error 010424AS1551: Failed to reset the password!`);
        }
    }

    static async AddUser(data) {
        try {
            const response = await fetch(`${apiPrefix}/AddUser`, {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${getNkten()}`
                },
                body: JSON.stringify(data)
            });
    
            if (!response.ok) {
                return GenerateReturntype(null, false, `Error 180324AS1739: Error in adding user`);
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            return GenerateReturntype(null, false, `Error 230124AS1259: Login Failed`);
        }
    }


    static async EditUser(data) {
        try {
            const response = await fetch(`${apiPrefix}/EditUser`, {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${getNkten()}`
                },
                body: JSON.stringify(data)
            });
    
            if (!response.ok) {
                return GenerateReturntype(null, false, `Error 010424AS1431: Error in editing the user`);
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            return GenerateReturntype(null, false, `Error 010424AS1432: Error in editing the user`);
        }
    }

    static async DeleteUser(data) {
        try {
            const response = await fetch(`${apiPrefix}/DeleteUser`, {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${getNkten()}`
                },
                body: JSON.stringify(data)
            });
    
            if (!response.ok) {
                return GenerateReturntype(null, false, `Error 010424AS1547: Error in deleting the user`);
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            return GenerateReturntype(null, false, `Error 010424AS1548: Error in deleting the user`);
        }
    }

    static async CheckAuthentication() {
        try {
            const response = await fetch(`${apiPrefix}/CheckAuthenticationStatus`, {
                method: 'GET',
                headers: {
                    'Authorization': `${getNkten()}`
                }
            });
    
            if (!response.ok) {
                return GenerateReturntype(null, false, `Error 240124AS1259: User Not Authenticated`);
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            return GenerateReturntype(null, false, `Error 240124AS1259: User Not Authenticated`);
        }
    }


    static async GetVehiclesData() {
        try {
            const response = await fetch(`${apiPrefix}/GetVehiclesData`, {
                method: 'GET',
                headers: {
                    'Authorization': `${getNkten()}`
                }
            });
    
            if (!response.ok) {
                return GenerateReturntype(null, false, `Error 240124AS1259: Error while getting vehicle data`);
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            return GenerateReturntype(null, false, `Error 240124AS1122: Error while getting vehicle data`);
        }
    }


    static async GetUserSettings() {
        try {
            const response = await fetch(`${apiPrefix}/GetUserSettings`, {
                method: 'GET',
                headers: {
                    'Authorization': `${getNkten()}`
                }
            });
    
            if (!response.ok) {
                return GenerateReturntype(null, false, `Error 210324AS1204: Error while getting user settings`);
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            return GenerateReturntype(null, false, `Error 210324AS1202: Error while getting user settings`);
        }
    }


    static async GetAllUsers() {
        try {
            const response = await fetch(`${apiPrefix}/GetAllUsers`, {
                method: 'GET',
                headers: {
                    'Authorization': `${getNkten()}`
                }
            });
    
            if (!response.ok) {
                return GenerateReturntype(null, false, `Error 04012024AS1118: Error while getting users`);
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            return GenerateReturntype(null, false, `Error 04012024AS1119: Error while getting users`);
        }
    }

    


    static async GetMechanicPin() {
        try {
            const response = await fetch(`${apiPrefix}/GetMechanicPin`, {
                method: 'GET',
                headers: {
                    'Authorization': `${getNkten()}`
                }
            });
    
            if (!response.ok) {
                return GenerateReturntype(null, false, `Error 230424AS1524: Error while fetching mechanic pin`);
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            return GenerateReturntype(null, false, `Error 230424AS1534: Error while fetching mechanic pin`);
        }
    }

    static async CreateMechanicPin(data) {
        try {
            const response = await fetch(`${apiPrefix}/CreateMechanicPin`, {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${getNkten()}`
                },
                body: JSON.stringify(data)
            });
    
            if (!response.ok) {
                return GenerateReturntype(null, false, "Error 230424AS1725: Failed to generate the pin.");
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            return GenerateReturntype(null, false, "Error 230424AS1724: Failed to generate the pin.");
        }
    }


    static async GetAssignedRfid(data) {
        try {
            const response = await fetch(`${apiPrefix}/GetAssignedRfid`, {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${getNkten()}`
                },
                body: JSON.stringify(data)
            });
    
            if (!response.ok) {
                return GenerateReturntype(null, false, "Error 250424AS1358: Failed to fetch assigned RFID.");
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            return GenerateReturntype(null, false, "Error 250424AS1359: Failed to fetch assigned RFID.");
        }
    }


    static async ExpireMechanicPin() {
        try {
            const response = await fetch(`${apiPrefix}/ExpireMechanicPin`, {
                method: 'POST',
                headers: {
                    'Authorization': `${getNkten()}`
                }
            });
    
            if (!response.ok) {
                return GenerateReturntype(null, false, `Error 230424AS1524: Error while fetching mechanic pin`);
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            return GenerateReturntype(null, false, `Error 230424AS1534: Error while fetching mechanic pin`);
        }
    }

    static async GetUserDetail(data) {
        try {
            const response = await fetch(`${apiPrefix}/GetUserDetail`, {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${getNkten()}`
                },
                body: JSON.stringify(data)
            });
    
            if (!response.ok) {
                return GenerateReturntype(null, false, `Error 04012024AS1319: Error in getting user details`);
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            return GenerateReturntype(null, false, `Error 04012024AS1320: Error in getting user details`);
        }
    }

    static async GetVehicleDetails(data) {
        try {
            const response = await fetch(`${apiPrefix}/GetVehicleDetails`, {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${getNkten()}`
                },
                body: JSON.stringify(data)
            });
    
            if (!response.ok) {
                return GenerateReturntype(null, false, `Error 140224AS1359: Error while fetching commands`);
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            return GenerateReturntype(null, false, `Error 140224AS1459: Error while fetching commands`);
        }
    }


    static async GetLockUnlockCommands(data) {
        try {
            const response = await fetch(`${apiPrefix}/GetLockAndUnlockCommand`, {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${getNkten()}`
                },
                body: JSON.stringify(data)
            });
    
            if (!response.ok) {
                return GenerateReturntype(null, false, `Error 250124AS1359: Error while fetching commands`);
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            return GenerateReturntype(null, false, `Error 240124AS1459: Error while fetching commands`);
        }
    }



    static async GetRelayLastStatus(data) {
        try {
            const response = await fetch(`${apiPrefix}/GetRelayLastStatus`, {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${getNkten()}`
                },
                body: JSON.stringify(data)
            });
    
            if (!response.ok) {
                return GenerateReturntype(null, false, `Error 300424AS1001: Error while Relay Last Status`);
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            return GenerateReturntype(null, false, `Error 300424AS1002: Error while Relay Last Status ${error.message}`);
        }
    }


    static async GetActivityLog(data) {
        try {
            const response = await fetch(`${apiPrefix}/GetActivityLog`, {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${getNkten()}`
                },
                body: JSON.stringify(data)
            });
    
            if (!response.ok) {
                return GenerateReturntype(null, false, `Error 090524AS1243: Unable to get Get Activity Log`);
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            return GenerateReturntype(null, false, `Error 090524AS1244: Unable to get Get Activity Log ${error.message}`);
        }
    }


    static async SendMessageToDevice(data) {
        try {
            const response = await fetch(`${apiPrefix}/SendMessageToDevice`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${getNkten()}`
                },
                body: JSON.stringify(data)
            });
    
            if (!response.ok) {
                return GenerateReturntype(null, false, `Error 250124AS1359: Failed to send message to device`);
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            return GenerateReturntype(null, false, `Error 240124AS1459: Failed to send message to device`);
        }
    }


    static async SendCmdToDevice(data) {
        try {
            const response = await fetch(`${apiPrefix}/SendTcpCommand`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${getNkten()}`
                },
                body: JSON.stringify(data)
            });
    
            if (!response.ok) {
                return GenerateReturntype(null, false, `Error 250124AS1741: Failed to send command to device`);
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            return GenerateReturntype(null, false, `Error 240124AS1742: Failed to send command to device`);
        }
    }


    static async GetVehicleHistory(data) {
        try {
            const response = await fetch(`${apiPrefix}/GetVehicleHistory`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${getNkten()}`
                },
                body: JSON.stringify(data)
            });
    
            if (!response.ok) {
                return GenerateReturntype(null, false, `Error 250124AS1359: Failed to send message to device`);
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            return GenerateReturntype(null, false, `Error 240124AS1459: Failed to send message to device`);
        }
    }


    static async ReportAnIssue(data) {
        try {
            const response = await fetch(`${apiPrefix}/ReportAnIssue`, {
                method: 'POST',
                body: data
            });
    
            if (!response.ok) {
                return GenerateReturntype(null, false, `Error 300124AS1359: Failed to report an issue`);
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            return GenerateReturntype(null, false, `Error 300124AS1459: Failed to report an issue`);
        }
    }
}



function GenerateReturntype(data, success, message) {
    return { data, success, message }
}


export default WebRequest;
