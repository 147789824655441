import React from 'react';
import { Link } from 'react-router-dom';


function VehicleList({ vehicles }) {
    return (
        <div className='mt-5'>
            <ul role="list" className="grid gap-4">
            {vehicles.length ? vehicles.map((vehicle, index) => (
                <Link to={`/secure-lock/${vehicle.GPS_Object_Id}`}  key={index}>
                    <li className="li-vehicle-list">
                        <div className="center flex-col md:flex-row min-w-0 gap-x-4">
                            <span className="size-16 flex justify-center items-center flex-none rounded-full bg-gray-50">
                                <svg  fill="#000"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink" 
                                    width="64px"
                                    height="64px"
                                    viewBox="-3.14 -3.14 37.73 37.73"
                                    xmlSpace="preserve"
                                    stroke="#fff"
                                    strokeWidth="0.00031445"
                                    transform="matrix(-1, 0, 0, 1, 0, 0)rotate(0)"><g strokeWidth="0"></g><g strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.06289"></g><g> <g> <g> <path d="M7.592,16.86c-1.77,0-3.203,1.434-3.203,3.204s1.434,3.204,3.203,3.204c1.768,0,3.203-1.434,3.203-3.204 S9.36,16.86,7.592,16.86z M7.592,21.032c-0.532,0-0.968-0.434-0.968-0.967s0.436-0.967,0.968-0.967 c0.531,0,0.966,0.434,0.966,0.967S8.124,21.032,7.592,21.032z"></path> <path d="M30.915,17.439l-0.524-4.262c-0.103-0.818-0.818-1.418-1.643-1.373L27.6,11.868l-3.564-3.211 c-0.344-0.309-0.787-0.479-1.249-0.479l-7.241-0.001c-1.625,0-3.201,0.555-4.468,1.573l-4.04,3.246l-5.433,1.358 c-0.698,0.174-1.188,0.802-1.188,1.521v1.566C0.187,17.44,0,17.626,0,17.856v2.071c0,0.295,0.239,0.534,0.534,0.534h3.067 c-0.013-0.133-0.04-0.26-0.04-0.396c0-2.227,1.804-4.029,4.03-4.029s4.029,1.802,4.029,4.029c0,0.137-0.028,0.264-0.041,0.396 h8.493c-0.012-0.133-0.039-0.26-0.039-0.396c0-2.227,1.804-4.029,4.029-4.029c2.227,0,4.028,1.802,4.028,4.029 c0,0.137-0.026,0.264-0.04,0.396h2.861c0.295,0,0.533-0.239,0.533-0.534v-1.953C31.449,17.68,31.21,17.439,30.915,17.439z M20.168,12.202l-10.102,0.511L12,11.158c1.051-0.845,2.357-1.305,3.706-1.305h4.462V12.202z M21.846,12.117V9.854h0.657 c0.228,0,0.447,0.084,0.616,0.237l2.062,1.856L21.846,12.117z"></path> <path d="M24.064,16.86c-1.77,0-3.203,1.434-3.203,3.204s1.434,3.204,3.203,3.204c1.769,0,3.203-1.434,3.203-3.204 S25.833,16.86,24.064,16.86z M24.064,21.032c-0.533,0-0.967-0.434-0.967-0.967s0.434-0.967,0.967-0.967 c0.531,0,0.967,0.434,0.967,0.967S24.596,21.032,24.064,21.032z"></path> </g> </g> </g>
                                </svg>
                            </span>
                            
                            <div className="min-w-0 flex-auto text-center md:text-left">
                                <p className="text-sm font-semibold leading-6 text-gray-900">{vehicle.VehicleName}</p>
                                <p className="mt-1 text-xs leading-5 text-gray-500">{vehicle.Address}</p>
                            </div>
                        </div>
                    </li>
                </Link>
                )) : "No Vehicles available"}
            </ul>
        </div>
    );
}

export default VehicleList;
