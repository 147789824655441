import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { checkUpdates } from '../../common/common';
import { Avatar, Divider } from '@mui/material';
import WebRequest from '../../WebRequest';
import { useDispatch, useSelector } from 'react-redux';
import { changeSettings } from '../../features/usersettings/usersettingsSlice';


function OffCanvas({ offCanvasSate }) {
    const dispatch = useDispatch();
    const usersettings = useSelector((state) => state.userSettings.value);
    const { isOffCanvasVisible, closeOffCanvas } = offCanvasSate;
    const navigate = useNavigate();
    const logoutUser = () => {
        localStorage.clear();
        navigate("/login");
    };

    useEffect(() => {
        const getUserSettings = async () => {
            const response = await WebRequest.GetUserSettings();
            if (response.success) {
                dispatch(changeSettings({ historyAccess: response.data.historyAccess, username: response.data.username, userType: response.data.userType, hasRfidCard: response.data.hasRfidCard }))
            }
        }

        if (!usersettings || usersettings.username === null || usersettings.userType === null) {
            getUserSettings();
        }
    }, [])

    return (
        <>
            <div>
                <div className={`${isOffCanvasVisible ? 'opacity-50' : 'opacity-0 hidden'} z-40 dvh-full w-screen bg-black top-0 left-0 fixed transition-opacity duration-500 ease-linear`} onClick={closeOffCanvas} />
                <div className={`z-50 bg-white dvh-full w-72 fixed top-0 right-0 ${isOffCanvasVisible ? 'translate-x-0' : 'translate-x-full'} transition-translate duration-200 ease-linear`}>
                    <div className='flex flex-col justify-between items-center w-full h-full'>
                        <div className='flex underline-shadow py-2 pl-4 w-full items-center'>
                            <img src='resources/icons/android-chrome-512x512.png' className='h-8 mr-4 rounded-sm'/>
                            <div className='flex logo flex-1 justify-between items-center'>
                                <h1 className='text-2xl font-medium text-gray-900 flex-1'>
                                    StopTheft
                                </h1>
                            </div>
                            <button type='button' onClick={closeOffCanvas} className="material-symbols-outlined p-4">close</button>
                        </div>

                        <ul className="flex flex-col w-full py-4 pr-4 flex-1">
                            {usersettings && (usersettings.userType == "Admin" || usersettings.userType == "iimAdmin") && <>
                                <li onClick={closeOffCanvas} >
                                    <Link to={`/users`} className="site-menu-item" >
                                        <span className="material-symbols-outlined">
                                            group
                                        </span>
                                        Users
                                    </Link>
                                </li>
                                <li onClick={closeOffCanvas} >
                                    <Link to={`/pin`} className="site-menu-item" >
                                        <span className="material-symbols-outlined">
                                            pin
                                        </span>
                                        Guest Login
                                    </Link>
                                </li>
                            </>}

                            <li onClick={checkUpdates} >
                                <a href='/' className="site-menu-item">
                                    <span className="material-symbols-outlined">
                                        security_update_good
                                    </span>
                                    Refresh App
                                </a>
                            </li>
                            <li onClick={closeOffCanvas} >
                                <Link to={`/reportAnIssue`} className="site-menu-item" >
                                    <span className="material-symbols-outlined">
                                        bug_report
                                    </span>
                                    Report an Issue
                                </Link>
                            </li>
                            <li onClick={closeOffCanvas} >
                                <button onClick={logoutUser} className="site-menu-item" >
                                    <span className="material-symbols-outlined">
                                        logout
                                    </span>
                                    Logout
                                </button>
                            </li>
                        </ul>
                        <div className='w-full'>
                            <Divider />
                            {usersettings && usersettings.username &&
                                <div className='py-2 px-4 bg-gray-100'>
                                    <div className='flex items-center'>
                                        {
                                            usersettings.username == "Mechanic" ?
                                                <>
                                                    <div className='w-1/4'>
                                                        <img src='/resources/images/mechanic.svg' className='mr-2' />
                                                    </div>

                                                    <span className='text-gray-600 w-52 truncate'>Guest</span>
                                                </>
                                                :
                                                <>
                                                    <div className='w-1/4'>
                                                        <Avatar sx={{ bgcolor: "#DC2626" }}>{usersettings.username[0].toUpperCase()}</Avatar>
                                                    </div>

                                                    <span className='text-gray-600 w-52 truncate'>{usersettings.username}</span>
                                                </>
                                        }
                                    </div>
                                </div>
                            }
                            <Divider />
                            <div className='py-2 px-4 w-full text-sm text-gray-500 text-center'>
                                Version 5
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OffCanvas
